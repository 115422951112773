<script setup lang="ts">
  import { MpDivider, MpIcon, MpMenu, MpText } from '@borg/ui';

  const sidebar = leftSidebar.inject();
</script>

<template>
  <MpMenu>
    <LinksLocale
      to="https://poslodavci.poslovac.hr"
      target="_blank"
      class="external-link"
      @click="sidebar.toggle"
    >
      <MpText link-plain>
        {{ $t('HEADER_WIDGETS.FOR_EMPLOYERS') }}
      </MpText>
      <MpIcon
        class="external-link__icon"
        icon="new_window"
        size="sm"
      />
    </LinksLocale>
  </MpMenu>
</template>

<style scoped lang="scss">
  .external-link {
    display: flex;
    align-items: center;
    gap: var(--mp-space-30);

    &__icon {
      color: var(--mp-color-primary);
    }
  }
</style>
